// App.js
import React, { useEffect } from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import Hero from "./components/Hero";
import Products from "./components/Products";
import Sustainability from "./components/Sustainability";
import Events from "./components/Events";
import Footer from "./components/Footer";
import Logo from "./img/logo.png";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: require('./locales/en.json'),
        },
        et: {
            translation: require('./locales/et.json'),
        },
        ru: {
            translation: require('./locales/ru.json'),
        },
    },
    lng: "et",
    fallbackLng: "et",
    interpolation: {
        escapeValue: false,
    },
});

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const languageLinks = [
        { code: "et", path: "/" },
        { code: "en", path: "/en" },
        { code: "ru", path: "/ru" },
    ];

    return (
        <div className="container mx-auto max-w-[1156px] relative">
            <div className="md:absolute flex right-0 z-10 pb-6 px-4 pt-[23px] lg:pt-[52px] items-center justify-between gap-4 w-full lg:w-auto bg-[#F4EB2B] md:bg-transparent">

                <div className="lg:order-1 flex gap-2 my-auto">
                    {languageLinks.map(({ code, path }) => (
                        <button key={code} onClick={() => changeLanguage(code)} className={location.pathname === path ? "opacity-30" : "opacity-100"}>
                            <Link to={path}>
                                <img className="lg:max-w-[41px]" src={`https://mesikäpp.eu/products/${code}.svg`} alt="" />
                            </Link>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Home = () => {
    const { t } = useTranslation();

    return (
        <>
            <Hero />
            <Products />
            <Sustainability />
            <Events />
            <Footer />
        </>
    );
};

const App = () => {
    const { i18n } = useTranslation();

    // Function to set language based on the route
    const setLanguageFromRoute = () => {
        const path = window.location.pathname;
        const langCode = path.replace('/', '').toLowerCase();
        const supportedLanguages = ['et', 'en', 'ru'];

        if (supportedLanguages.includes(langCode)) {
            i18n.changeLanguage(langCode);
        }
    };

    useEffect(() => {
        setLanguageFromRoute();
        return () => {
            // Perform any cleanup if needed
        };
    }, []);

    return (
        <Router>
            <LanguageSwitcher />
            <Routes>
                {["", "en", "ru"].map((lng) => (
                    <Route key={lng} path={`/${lng.toUpperCase()}`} element={<Home />} />
                ))}
            </Routes>
        </Router>
    );
};

export default App;
