import React from "react";
import { useTranslation } from "react-i18next";
import Hero_bg from "../img/est_hero.png";

const Hero = () => {
    const { t } = useTranslation();

    return (
      <a className="" href={"https://kalev.eu/mesikapa-loos"} target={"_blank"}>
          <img className="cursor-pointer w-full h-auto hidden md:block" src={t("hero_img")} alt=""/>
          <img className="cursor-pointer w-full h-auto md:hidden" src={t("hero_img_mob")} alt=""/>

      </a>
    );
};

export default Hero;